<script>
import moment from 'moment';
import 'moment/dist/locale/es';
moment.locale('es');
export default {
  props: {
    companyId: Number,
    initialReloadingBills: Boolean,
    initialBills: Array,
    initialLastChecked: String,
  },
  data() {
    return {
      reloading: '',
      bills: [],
      lastChecked: 'sin consulta',
    };
  },
  methods: {
    async fetchData() {
      this.fetchBills();
      let url = `/bills_reloading/${this.companyId}`
      const response = await fetch(url)
      const reloading = await response.json() === 'true'
      //check if status is completed, if it is stop polling
      if (!reloading) {
          clearInterval(this.pollInterval) //won't be polled anymore
          this.reloading = reloading;
      }
    },
    async fetchBills() {
      let url = `/bills_fetching/${this.companyId}`
      const response = await fetch(url);
      const bills = await response.json();
      this.bills = bills;
      this.fetchLastChecked();
    },
    async fetchLastChecked() {
      let url = `/bills_last_checked_fetching/${this.companyId}`
      const response = await fetch(url);
      const lastChecked = await response.json();
      if(lastChecked != null) {
        this.lastChecked = lastChecked;
      }
    },
    billStatusFormat(status) {
      if(status == 'Cotizar') {
        return 'text-[#3fc965] bg-[#e2feea]'
      } else if(status == 'En proceso') {
        return 'text-[#2876b0] bg-[#b5ddfb]'
      } else if(status == 'Financiada') {
        return 'text-[#7621ff] bg-[#e5cbfe]'
      }
    },
    billHumanStatuses(status){
      if(status == 'available') {
        return "Cotizar";
      } else if(status == 'requested') {
        return "En proceso";
      } else if(status == 'funded') {
        return "Financiada";
      }
    },
    rutFormatter(rut) {
      if(rut == null) {
        return rut;
      } else {
        let stripped_rut = rut.replace('-', '').replace('.', '').replace(',', '');
        let uppercase_stripped_rut = stripped_rut.toUpperCase();
        let reversed_uppercase_stripped_rut = uppercase_stripped_rut.split("").reverse().join("");
        let string1 = reversed_uppercase_stripped_rut.slice(0, 1) + '-' + reversed_uppercase_stripped_rut.slice(1);
        let string2 = string1.slice(0, 5) + '.' + reversed_uppercase_stripped_rut.slice(4);
        let string3 = string2.slice(0, 9) + '.' + reversed_uppercase_stripped_rut.slice(7);
        return string3.split("").reverse().join("");
      }
    },
		formatPrice(value) {
			const formatter = Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });
			return formatter.format(value);
    },
    fundedBill(status) {
      return status != 'available'
    },
    moment(date) {
      if(date == 'sin consulta') return date;
      return moment(Date.parse(date)).format('LL, LTS');
    },
  },
  computed: {
    billLoaderVisibility() {
      return this.reloading === true
    },
  },
  mounted() {
    this.reloading = this.initialReloadingBills;
    this.bills = this.initialBills
    if(this.initialLastChecked != null) {
      this.lastChecked = this.initialLastChecked;
    }
    //check if the status is completed, if not fetch data every 1 minute.
    if (this.reloading != false) {
        this.pollInterval = setInterval(this.fetchData, 30000) //save reference to the interval
        setTimeout(() => { clearInterval(this.pollInterval) }, 36000000) //stop polling after an hour
    } else {
     }
  },
}
</script>

<template>
  <slot :reloading="reloading" :bills="bills" :last-checked="lastChecked"
        :fetch-data="fetchData" :fetch-bills="fetchBills" :fetch-last-checked="fetchLastChecked"
        :bill-loader-visibility="billLoaderVisibility" :bill-status-format="billStatusFormat" :rut-formatter="rutFormatter"
        :bill-human-statuses="billHumanStatuses" :format-price="formatPrice" :funded-bill="fundedBill" :moment="moment"></slot>
</template>