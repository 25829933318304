<script>
export default {
  props: {
    getForm: Function
  },
  data() {
	  return {
      sending: false,
  	}
	},
  methods: {
    submitForm() {
      const form = this.getForm()
      let x = form["rut"].value.replace('.','').replace('.','').replace('-','')
      if(form["rut"].value == "") {
        alert("Debes escribir un RUT");
        return false;
      } else if(form["clave"].value == "") {
        alert("Debes escribir una contraseña");
        return false;
      } else if(this.rutVerifier(x) == false) { 
        alert("El RUT no es válido. Intenta nuevamente");
        return false;
      } else {
        this.sending = true
        form.submit()
      }

    },
    rutVerifier(rut) {
      var digit = rut.at(-1);
      var body = rut.substring(0,rut.length-1);
      if (digit == 'K') digit = 'k';
      return (this.dv(body) == digit);
    },
    dv(T) {
        var M = 0,
            S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + T % 10 * (9 - M++ % 6)) % 11;
        return S ? S - 1 : 'k';
    }
  }
}
</script>

<template>
  <slot :submit-form="submitForm" :sending="sending"></slot>
</template>