<script>
export default {
	props: {
		billTotalAmount: Number,
		collectionExpenses: Number,
		stepThreeMonthlyRate: Number,
		stepThreeFundingPercentage: Number,
		stamp: Number,
		plusFactorTable: Array,
		emissionDate: String,
		arrearsFee: Number
	},
	inject: ["eventBus"],
  data() {
	  return {
			days: 30,
			today: new Date(),
			dateSelected: new Date(),
			fundingDays: 30,
			contractScore: "",
  	};
	},
	methods: {
		selectContractChange(event){
			const opts = event.target.options;
			this.contractScore = opts[opts.selectedIndex].getAttribute('data-score');
		},
		selectChange(event){
			this.days = parseInt(event.target.value);
		},
		formatPrice(value) {
			const formatter = Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });
			return formatter.format(value);
    },
		formatAsPercent(value) {
			const formatter =  Intl.NumberFormat('default', {
				style: 'percent',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			return formatter.format(value / 100);
		},
	},
	computed: {
		paymentDate(){
			let dateEmissionDate = new Date(this.emissionDate)
			let d = new Date(dateEmissionDate)
			d.setDate(d.getDate() + this.days)
			var Difference_In_Time = d.getTime() - this.today.getTime()
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			if(Difference_In_Days > 0){
				this.fundingDays = Math.ceil(Difference_In_Days)
			}
			else {
				this.fundingDays = 0
			}
			this.eventBus.emit("fundingDaysChanged", this.fundingDays)
			this.dateSelected = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2)
			return false
		},
		fundingDaysAreNegative(){
			return this.fundingDays == 0
		},
		monthlyRate(){
			let factorRate = this.plusFactorTable.find(obj => obj[0] == this.contractScore)[1] / 10_000;
			return this.stepThreeMonthlyRate + factorRate;
		},
		monthlyRateArrears(){
			return this.monthlyRate * 100 + this.arrearsFee;
		},
		fundingPercentage(){
			let factorRetention = this.plusFactorTable.find(obj => obj[0] == this.contractScore)[2] / 100;
			return this.stepThreeFundingPercentage - factorRetention;
		},
		fundedAmountPlus(){
			return this.billTotalAmount * this.fundingPercentage;
		},
		dailyInterestPlus(){
			return (1 + this.monthlyRate)**(1.0 / 30);
		},
		interestAmountPlus() {
			return (this.fundedAmountPlus * (this.dailyInterestPlus)**this.fundingDays ) - this.fundedAmountPlus;
		},
		dailyOperationalCostPlus() {
			return (this.fundedAmountPlus * this.stamp) / 30;
		},
		operationalCostPlus() {
			return (this.dailyOperationalCostPlus * this.fundingDays) + this.collectionExpenses;
		},
		operationalCostIvaPlus() {
			return this.operationalCostPlus * 0.19;
		},
		costTotalAmountPlus() {
			return this.interestAmountPlus + this.operationalCostPlus + this.operationalCostIvaPlus;
		},
		liquidatedAmountPlus() {
			return this.fundedAmountPlus - this.costTotalAmountPlus;
		},
		retentionAmountPlus() {
			return this.billTotalAmount - this.fundedAmountPlus;
		},
	},
}
</script>

<template>
	<slot :days="days" :payment-date="paymentDate" :date-selected="dateSelected" :select-change="selectChange" :select-contract-change="selectContractChange" 
				:format-price="formatPrice" :format-as-percent="formatAsPercent" :contract-score="contractScore" :monthly-rate="monthlyRate" :funding-percentage="fundingPercentage"
				:funded-amount-plus="fundedAmountPlus" :daily-interest-plus="dailyInterestPlus" :interest-amount-plus="interestAmountPlus"
				:daily-operational-cost-plus="dailyOperationalCostPlus" :operational-cost-plus="operationalCostPlus"
				:operational-cost-iva-plus="operationalCostIvaPlus" :cost-total-amount-plus="costTotalAmountPlus"
				:liquidated-amount-plus="liquidatedAmountPlus" :retention-amount-plus="retentionAmountPlus"
				:monthly-rate-arrears="monthlyRateArrears" :funding-days="fundingDays" :funding-days-are-negative="fundingDaysAreNegative"></slot>
</template>