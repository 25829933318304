<script>
export default {
  props: {
    getForm: Function
  },
  inject: ["eventBus"],
  data() {
	  return {
      uploaded: false,
      fileUpload1: false,
      fileUpload2: false,
      fileUpload3: false,
      dateSlotFundingDays: 1,
      sending: false
  	}
	},
  methods: {
    uploadChecker() {
      if (this.fileUpload1 == true && this.fileUpload2 == true && this.fileUpload3 == true) {
        this.uploaded = true;
      } else {
        this.uploaded = false;
      }
    },
    fileChange1(event) {
      this.fileUpload1 = !!event.target.files.length;
      this.uploadChecker();
    },
    fileChange2(event) {
      this.fileUpload2 = !!event.target.files.length;
      this.uploadChecker();
    },
    fileChange3(event) {
      this.fileUpload3 = !!event.target.files.length;
      this.uploadChecker();
    },
    sendingToTrue() {
      const form = this.getForm();
      this.sending = true;
      form.submit();
    }
    
  },
  computed: {
    positiveFundingDays(){
      return this.dateSlotFundingDays > 0;
    },
    canRequestFunding(){
      return this.positiveFundingDays && this.uploaded && !this.sending;
    },
    buttonTitle() {
      if(this.positiveFundingDays === false){
        return "No puedes solicitar financiamiento si los días de financiamiento son iguales a cero";
      } else if(this.uploaded === false){
        return "Debes subir los tres archivos requeridos para poder solicitar tu evaluación";
      } else {
        return "Envía tu solicitud de financiamiento";
      }
    },
  },
  mounted () {
    this.eventBus.on("fundingDaysChanged", (param) => {
      this.dateSlotFundingDays = param;
    })
  }
}
</script>

<template>
  <slot :upload-checker="uploadChecker" :uploaded="uploaded" :file-upload-1="fileUpload1" :file-upload-2="fileUpload2"
        :file-upload-3="fileUpload3" :file-change-1="fileChange1" :file-change-2="fileChange2"
        :file-change-3="fileChange3" :sending-to-true="sendingToTrue"
        :date-slot-funding-days="dateSlotFundingDays" :can-request-funding="canRequestFunding"
        :positive-funding-days="positiveFundingDays" :button-title="buttonTitle" :sending="sending"></slot>
</template>