
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import { createApp } from 'vue/dist/vue.esm-bundler'
import mitt from 'mitt';
import CompanySignIn from "../components/CompanySignIn.vue"
import TgrCard from "../components/TgrCard.vue"
import BillLoaderDisabler from "../components/BillLoaderDisabler.vue"
import BillEvaluationButtonDisabler from "../components/BillEvaluationButtonDisabler.vue"
import FundingDaysAssigner from "../components/FundingDaysAssigner.vue"
import FundingDaysAssignerPlus from "../components/FundingDaysAssignerPlus.vue"


const app = createApp({
  data() {
    return {
    }
  },
  methods: {
    getForm() {
      return this.$refs.formRef
    }
  },
});
const eventBus = mitt();
app.provide('eventBus',eventBus);
app.component('CompanySignIn', CompanySignIn)
app.component('TgrCard', TgrCard)
app.component('BillLoaderDisabler', BillLoaderDisabler)
app.component('BillEvaluationButtonDisabler', BillEvaluationButtonDisabler)
app.component('FundingDaysAssigner', FundingDaysAssigner)
app.component('FundingDaysAssignerPlus', FundingDaysAssignerPlus)
app.mount('#app');

