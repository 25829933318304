<script>
export default {
	props: {
		fundedAmount: Number,
		dailyInterest: Number,
		dailyOperationalCost: Number,
		collectionExpenses: Number,
		emissionDate: String
	},
	inject: ["eventBus"],
  data() {
	  return {
			days: 30,
			today: new Date(),
			dateSelected: new Date(),
			fundingDays: 30,
  	}
	},
	methods: {
		selectChange(event){
			this.days = parseInt(event.target.value);
		},
		formatPrice(value) {
			const formatter = Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });
			return formatter.format(value);
    },
	},
	computed: {
		paymentDate(){
			let dateEmissionDate = new Date(this.emissionDate)
			let d = new Date(dateEmissionDate)
			d.setDate(d.getDate() + this.days)
			var Difference_In_Time = d.getTime() - this.today.getTime()
			var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
			if(Difference_In_Days > 0){
				this.fundingDays = Math.ceil(Difference_In_Days)
			}
			else {
				this.fundingDays = 0
			}
			this.eventBus.emit("fundingDaysChanged", this.fundingDays)
			this.dateSelected = d.getFullYear() + '-' + ("0" + (d.getMonth() + 1)).slice(-2) + '-' + ("0" + d.getDate()).slice(-2)
			return false
		},
		fundingDaysAreNegative(){
			return this.fundingDays == 0
		},
		interestAmount() {
			return (this.fundedAmount * (this.dailyInterest)**this.fundingDays ) - this.fundedAmount;
		},
		operationalCost() {
			return (this.dailyOperationalCost * this.fundingDays) + this.collectionExpenses;
		},
		operationalCostIva() {
			return this.operationalCost * 0.19;
		},
		costTotalAmount() {
			return this.interestAmount + this.operationalCost + this.operationalCostIva;
		},
		liquidatedAmount() {
			return this.fundedAmount - this.costTotalAmount;
		},
	},
}
</script>

<template>
	<slot :days="days" :payment-date="paymentDate" :date-selected="dateSelected" :funding-days="fundingDays"
				:select-change="selectChange" :format-price="formatPrice" :interest-amount="interestAmount"
				:operational-cost="operationalCost" :operational-cost-iva="operationalCostIva"
				:cost-total-amount="costTotalAmount" :liquidated-amount="liquidatedAmount"
				:funding-days-are-negative="fundingDaysAreNegative"></slot>
</template>