<script>
import moment from 'moment';
import 'moment/dist/locale/es';
moment.locale('es');
export default {
  props: {
    companyId: Number,
    initialReloading: Boolean,
    initialTgrDebt: String,
    initialLastCheckedTgr: String,
  },
  data() {
    return {
      reloading: '',
      tgrDebt: '',
      lastCheckedTgr: 'sin consulta',
    };
  },
  methods: {
    async fetchData() {
      this.fetchTgr();
      let url = `/tgr_reloading/${this.companyId}`
      const response = await fetch(url)
      const reloading = await response.json() === 'true'
      //check if status is completed, if it is stop polling
      if (!reloading) {
          clearInterval(this.pollInterval) //won't be polled anymore 
          this.reloading = reloading;
      }
    },
    async fetchTgr() {
      let url = `/tgr_fetching/${this.companyId}`
      const response = await fetch(url);
      const tgrDebt = await response.json();
      this.tgrDebt = tgrDebt;
      this.fetchLastCheckedTgr();
    },
    async fetchLastCheckedTgr() {
      let url = `/tgr_last_checked_fetching/${this.companyId}`
      const response = await fetch(url);
      const lastCheckedTgr = await response.json();
      if(lastCheckedTgr != null) {
        this.lastCheckedTgr = lastCheckedTgr;
      }
    },
    formatPrice(value) {
			const formatter = Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' });
      if(value == '...') return value;
			return formatter.format(Number(value));
    },
    moment(date) {
      if(date == 'sin consulta') return date;
      return moment(Date.parse(date)).format('LL, LTS');
    },
  },
  computed: {
    tgrLoaderVisibility() {
      return this.reloading === true
    }
  },
  mounted() {
    this.reloading = this.initialReloading
    this.tgrDebt = this.initialTgrDebt
    if(this.initialLastCheckedTgr != null) {
      this.lastCheckedTgr = this.initialLastCheckedTgr;
    }
    //check if the status is completed, if not fetch data every 1 minute.     
    if (this.reloading != false) {
        this.pollInterval = setInterval(this.fetchData, 60000) //save reference to the interval
        setTimeout(() => { clearInterval(this.pollInterval) }, 36000000) //stop polling after an hour
    } else { 
     }
  },
}
</script>

<template>
  <slot :reloading="reloading" :tgr-debt="tgrDebt" :last-checked-tgr="lastCheckedTgr"
        :fetch-data="fetchData" :fetch-tgr="fetchTgr" :fetch-last-checked-tgr="fetchLastCheckedTgr"
        :tgr-loader-visibility="tgrLoaderVisibility" :format-price="formatPrice" :moment="moment"></slot>
</template>